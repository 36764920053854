import React from 'react'

const Logo = props => (
  <svg viewBox="0 0 220.24 36" {...props}>
    <g data-name="Layer 2">
      <path
        d="M14.94 35.7H0V4a4 4 0 0 1 4-4h10.34c7 0 11 3.91 11 10A8.26 8.26 0 0 1 21 17.4a8.3 8.3 0 0 1 4.91 8c-.04 6.69-4.6 10.3-10.97 10.3zM13.79 6.22H7v7a1.21 1.21 0 0 0 1.21 1.21h5.61c3 0 4.61-1.65 4.61-4.11s-1.68-4.1-4.64-4.1zm.45 14.49H7v7.56a1.21 1.21 0 0 0 1.21 1.21h6.06c3.16 0 4.66-2 4.66-4.41s-1.53-4.36-4.69-4.36zM72.72 36c-7.32 0-13.14-5-13.14-12.53V0h3a4 4 0 0 1 3.95 4v19.21c0 4.12 2.41 6.57 6.17 6.57s6.21-2.45 6.21-6.57V4a4 4 0 0 1 3.97-4h3v23.47C85.85 31 80 36 72.72 36zm39.97-.3L98.55 13.79V35.7h-7V0h4.89a2.45 2.45 0 0 1 2 1.11l13.42 20.75V4a3.94 3.94 0 0 1 4.02-4h3v35.7zm18.88-29.48v7.56a1.21 1.21 0 0 0 1.21 1.22h12.87l-2 4.72a2.42 2.42 0 0 1-2.24 1.5h-9.87V35.7h-7V4a3.94 3.94 0 0 1 4-4h19.57l-2 4.72a2.43 2.43 0 0 1-2.24 1.5zm20.69 29.48V4a4 4 0 0 1 4-3.95h3V35.7zm32.24 0l-7-14.24h-5V35.7h-7V4a4 4 0 0 1 4-4h10c7.27 0 11.58 5 11.58 10.93a9.63 9.63 0 0 1-6.52 9.38l8 15.39zm-5.41-29.48h-6.57v8.21a1.21 1.21 0 0 0 1.21 1.21h5.36c3.06 0 5.06-2 5.06-4.71s-2-4.71-5.06-4.71zm17.64 29.48V4a4 4 0 0 1 4-3.95h19.56l-2 4.72A2.44 2.44 0 0 1 216 6.22h-12.3v7.16a1.21 1.21 0 0 0 1.21 1.21h12.88l-2 4.72a2.43 2.43 0 0 1-2.24 1.5h-9.85v7.46a1.21 1.21 0 0 0 1.21 1.21h15.33l-2 4.72a2.44 2.44 0 0 1-2.24 1.5zM45.47 0H40L27 35.7h4.69a3.61 3.61 0 0 0 3.41-2.44l1.32-3.88 2-5.86 4.51-13 4.36 13 1.86 5.86 1.28 3.86a3.6 3.6 0 0 0 3.42 2.46h4.65z"
        data-name="Layer 1"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
      />
    </g>
  </svg>
)

export default Logo

export const LogoB = props => (
  <svg viewBox="0 0 25.87 35.7" {...props}>
    <g data-name="Layer 2">
      <path
        d="M14.94 35.7H0V4a4 4 0 0 1 4-4h10.34c7 0 11 3.91 11 10A8.26 8.26 0 0 1 21 17.4a8.3 8.3 0 0 1 4.91 8c-.04 6.69-4.6 10.3-10.97 10.3zM13.79 6.22H7v7a1.21 1.21 0 0 0 1.21 1.21h5.61c3 0 4.61-1.65 4.61-4.11s-1.68-4.1-4.64-4.1zm.45 14.49H7v7.56a1.21 1.21 0 0 0 1.21 1.21h6.06c3.16 0 4.66-2 4.66-4.41s-1.53-4.36-4.69-4.36z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const LogoA = props => (
  <svg viewBox="0 0 31.49 35.7" {...props}>
    <g data-name="Layer 2">
      <path
        d="M18.45 0H13L0 35.7h4.69a3.61 3.61 0 0 0 3.41-2.44l1.33-3.88 2-5.86 4.51-13 4.37 13 1.85 5.86 1.29 3.86a3.59 3.59 0 0 0 3.41 2.46h4.68z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const LogoU = props => (
  <svg viewBox="0 0 26.27 36" {...props}>
    <g data-name="Layer 2">
      <path
        d="M13.14 36C5.82 36 0 31 0 23.47V0h3a4 4 0 0 1 4 4v19.21c0 4.12 2.41 6.57 6.17 6.57s6.21-2.45 6.21-6.57V4a4 4 0 0 1 3.92-4h3v23.47C26.27 31 20.46 36 13.14 36z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const LogoN = props => (
  <svg viewBox="0 0 27.33 35.7" {...props}>
    <g data-name="Layer 2">
      <path
        d="M21.11 35.7L7 13.79V35.7H0V0h4.9a2.44 2.44 0 0 1 2 1.11l13.46 20.75V4a3.94 3.94 0 0 1 3.94-4h3v35.7z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const LogoF = props => (
  <svg viewBox="0 0 23.52 35.7" {...props}>
    <g data-name="Layer 2">
      <path
        d="M7 6.22v7.56A1.21 1.21 0 0 0 8.18 15h12.88l-2 4.72a2.43 2.43 0 0 1-2.24 1.5H7V35.7H0V4a4 4 0 0 1 4-4h19.52l-2 4.72a2.44 2.44 0 0 1-2.25 1.5z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const LogoI = props => (
  <svg viewBox="0 0 6.97 35.7" {...props}>
    <g data-name="Layer 2">
      <path
        d="M0 35.7V4a4 4 0 0 1 4-4h3v35.7z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const LogoR = props => (
  <svg viewBox="0 0 27.02 35.7" {...props}>
    <g data-name="Layer 2">
      <path
        d="M19 35.7l-7-14.24H7V35.7H0V4a4 4 0 0 1 4-4h10c7.27 0 11.58 5 11.58 10.93a9.63 9.63 0 0 1-6.52 9.38L27 35.7zM13.54 6.22H7v8.21a1.21 1.21 0 0 0 1.21 1.21h5.36c3.06 0 5.06-2 5.06-4.71s-2.03-4.71-5.09-4.71z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const LogoE = props => (
  <svg viewBox="0 0 23.52 35.7" {...props}>
    <g data-name="Layer 2">
      <path
        d="M0 35.7V4a4 4 0 0 1 4-4h19.52l-2 4.72a2.44 2.44 0 0 1-2.25 1.5H7v7.16a1.21 1.21 0 0 0 1.21 1.21h12.85l-2 4.72a2.43 2.43 0 0 1-2.24 1.5H7v7.46a1.21 1.21 0 0 0 1.21 1.21h15.31l-2 4.72a2.44 2.44 0 0 1-2.25 1.5z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const LogoCircle = props => (
  <svg viewBox="0 0 90 90" {...props}>
    <g data-name="Layer 2">
      <path
        d="M45 90a45 45 0 1 1 45-45 45.05 45.05 0 0 1-45 45zm0-83.38A38.38 38.38 0 1 0 83.38 45 38.43 38.43 0 0 0 45 6.62z"
        fill={`${props.theme === 'white' ? '#231f20' : 'white'}`}
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export const B = props => (
  <svg width={349} height={251} {...props}>
    <path
      d="M349.01 106.046V251H38.61A38.462 38.462 0 0 1 0 212.69V111.884C0 44.271 38.24 4.87 98.04 4.87c38.72 0 63.72 25.3 72.06 42.806 9.8-20.917 31.86-47.67 78.42-47.67 65.2 0 100.49 44.265 100.49 106.04zM60.79 117.233v66.157h68.51a11.837 11.837 0 0 0 11.88-11.79v-54.367c0-28.7-16.18-44.748-40.2-44.748s-40.19 16.052-40.19 44.748zm141.66-4.379v70.536h73.9a11.837 11.837 0 0 0 11.88-11.79v-58.746c0-30.643-19.61-45.235-43.14-45.235s-42.64 14.592-42.64 45.235z"
      fill={`${props.theme === 'white' ? '#f9f9f9' : '#272628'}`}
      fillRule="evenodd"
    />
  </svg>
)

export const A = props => (
  <svg width={341} height={394} {...props}>
    <path
      fillRule="evenodd"
      fill={`${props.theme === 'white' ? '#111' : '#272628'}`}
      d="M199.278.234h-59.01L.055 393.736h50.689c16.63 0 31.417-10.8 36.787-26.865l14.299-42.774 21.112-64.658 48.72-143.143 47.099 143.143 20.035 64.658 13.873 42.502c5.291 16.206 20.144 27.137 36.877 27.137h50.479L199.278.234z"
    />
  </svg>
)

export const U = props => (
  <svg width={352} height={256} {...props}>
    <path
      fillRule="evenodd"
      fill={`${props.theme === 'white' ? '#f9f9f9' : '#272628'}`}
      d="M352 128.164c0 71.195-48.533 127.766-122.561 127.766H0v-29.38c0-21.21 17.285-38.404 38.608-38.404h188.377c40.201 0 64.225-23.413 64.225-59.982 0-36.576-24.024-60.469-64.225-60.469H38.608C17.285 67.695 0 50.501 0 29.291V.397h229.439C303.467.397 352 56.962 352 128.164z"
    />
  </svg>
)

export const N = props => (
  <svg width={350} height={267} {...props}>
    <path
      fillRule="evenodd"
      fill={`${props.theme === 'white' ? '#f9f9f9' : '#272628'}`}
      d="M349.059 61.399L134.819 198.92h214.24v67.784H0V219.09c0-8.018 4.087-15.49 10.856-19.844L213.748 68.72H38.608C17.285 68.72 0 51.525 0 30.316V.936h349.059v60.463z"
    />
  </svg>
)

export const Symbol = props => (
  <svg viewBox="0 0 90 90" {...props}>
    <path
      d="M48.07 21.43h-6.2L27.13 61.94h5.67a3.61 3.61 0 0 0 3.41-2.43l1.62-4.74 2.22-6.65 5.12-14.74 4.95 14.74 2.1 6.65 1.57 4.71a3.6 3.6 0 0 0 3.42 2.46h5.65z"
      fillRule="evenodd"
      fill={`${props.theme === 'white' ? '#272628' : '#f9f9f9'}`}
    />
    <path
      d="M45 89.99a45 45 0 1 1 45-45 45 45 0 0 1-45 45zm0-83.38a38.39 38.39 0 1 0 38.38 38.38A38.43 38.43 0 0 0 45 6.61z"
      fillRule="evenodd"
      fill={`${props.theme === 'white' ? '#272628' : '#f9f9f9'}`}
    />
  </svg>
)
